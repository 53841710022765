import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/ActivateEnlightenedMode.css';
import lightImage from '../images/light.png';

const stripePromise = loadStripe('your-public-stripe-key');

function ActivateEnlightenedMode() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [vipMode, setVipMode] = useState(false); // New state for VIP code mode
  const [vipCode, setVipCode] = useState(""); // New state for VIP code input
  const [vipError, setVipError] = useState(""); // New state for VIP code error message
  const navigate = useNavigate();
  const userName = sessionStorage.getItem('userName');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const handleVIPCode = () => {
    setVipMode(true); // Activate VIP code input mode
    setVipError(""); // Clear any previous error when reopening the input field
  };

  const handleVIPCodeChange = (event) => {
    setVipCode(event.target.value.toUpperCase());
    setVipError(""); // Clear the error message when the user starts typing a new code
  };

  const handleVIPCodeSubmit = async (event) => {
    event.preventDefault();
    console.log('VIP Code Submitted:', vipCode);
  
    try {
      const response = await fetch(`${apiBaseUrl}/api/users/check-vip-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vipCode }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // VIP code is correct, navigate to the PremiumLoading page
        navigate('/premium-loading');
      } else {
        // VIP code is incorrect, show an error message
        setVipError(data.message || 'VIP code is incorrect');
      }
    } catch (error) {
      console.error('Error checking VIP code:', error);
      setVipError('There was an error processing your request. Please try again.');
    }
  };

  const handlePayment = async () => {

    alert("Payment functionality is not yet available.");
    
    // Comment out or remove the below code until the payment feature is ready
    // try {
    //   sessionStorage.setItem('currentScreen', 'activate-enlightened-mode');
    //   sessionStorage.setItem('userName', userName);

    //   const response = await fetch(`${apiBaseUrl}/api/payments/create-checkout-session`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });

    //   const session = await response.json();
    //   const stripe = await stripePromise;
    //   const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

    //   if (error) {
    //     console.error('Stripe Checkout Error:', error);
    //     setPaymentStatus('error');
    //   }
    // } catch (error) {
    //   console.error('Error creating checkout session:', error);
    //   setPaymentStatus('error');
    // }
  };

  const handlePaymentResult = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatusParam = urlParams.get('payment_status');

    if (paymentStatusParam) {
      if (paymentStatusParam === 'succeeded') {
        setPaymentStatus('success');
      } else if (paymentStatusParam === 'canceled') {
        setPaymentStatus('canceled');
      } else {
        setPaymentStatus('error');
      }
    }
  };

  useEffect(() => {
    handlePaymentResult();
  }, []);

  return (
    <div className="activate-enlightened-mode">
      <h2>Activate Enlightened Mode</h2>
      <p><i>Choose an option below to unlock the full experience. Discover actions you can take today to get closer to your true self, receive book recommendations, and read a complete premium analysis.</i></p>
      <img src={lightImage} alt="Light" className="light-image" />
      <div className="buttons-container">
        {paymentStatus === 'success' && <p>Payment Successful! Enjoy Enlightened Mode.</p>}
        {paymentStatus === 'error' && <p>Payment Failed. Please try again.</p>}
        {paymentStatus === 'canceled' && <p>Payment Canceled. You have not been charged.</p>}

        {!paymentStatus && (
          <>
            {vipMode ? (
              <form onSubmit={handleVIPCodeSubmit} className="vip-input">
                <input
                  type="text"
                  value={vipCode}
                  onChange={handleVIPCodeChange}
                  maxLength="5"
                  placeholder="ENTER VIP CODE"
                  autoFocus
                />
                {vipError && <p className="vip-error-message">{vipError}</p>}
              </form>
            ) : (
              <button className="activate-button vip-button" onClick={handleVIPCode}>
                <span>Enter VIP Code</span>
              </button>
            )}
            <span className="text-between-buttons">or unlock with</span>
            <button className="activate-button payment-button" onClick={handlePayment}>
              <span>One-Time Purchase</span> <span className="button-price">3,99€</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ActivateEnlightenedMode;
