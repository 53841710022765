// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Premium Loading Screen Styles */
.premium-loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: #f5f5dc; /* Use the same background color for consistency */
    font-family: "Dancing Script";
    font-size: 1.2rem; /* Font size for loading messages */
    color: #333333; /* Text color */
  }
  
  .loader-gif {
    width: 8rem; /* Size of the loader GIF */
    height: 8rem;
    margin-bottom: 2rem; /* Space below the loader */
  }
  
  .ellipsis {
    display: inline-block;
    width: 1em; /* Fixed width for ellipsis */
    text-align: left;
    font-size: 1rem !important;
  }
  
  .premium-loading-screen p {
    font-size: 1.8rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/PremiumLoading.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,mBAAmB,EAAE,kDAAkD;IACvE,6BAA6B;IAC7B,iBAAiB,EAAE,mCAAmC;IACtD,cAAc,EAAE,eAAe;EACjC;;EAEA;IACE,WAAW,EAAE,2BAA2B;IACxC,YAAY;IACZ,mBAAmB,EAAE,2BAA2B;EAClD;;EAEA;IACE,qBAAqB;IACrB,UAAU,EAAE,6BAA6B;IACzC,gBAAgB;IAChB,0BAA0B;EAC5B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["/* Premium Loading Screen Styles */\n.premium-loading-screen {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100vw;\n    background: #f5f5dc; /* Use the same background color for consistency */\n    font-family: \"Dancing Script\";\n    font-size: 1.2rem; /* Font size for loading messages */\n    color: #333333; /* Text color */\n  }\n  \n  .loader-gif {\n    width: 8rem; /* Size of the loader GIF */\n    height: 8rem;\n    margin-bottom: 2rem; /* Space below the loader */\n  }\n  \n  .ellipsis {\n    display: inline-block;\n    width: 1em; /* Fixed width for ellipsis */\n    text-align: left;\n    font-size: 1rem !important;\n  }\n  \n  .premium-loading-screen p {\n    font-size: 1.8rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
