import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/NameInputScreen.css";
import { v4 as uuidv4 } from 'uuid'; 

function NameInputScreen() {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      const sessionId = uuidv4();
      sessionStorage.setItem('userName', name);
      sessionStorage.setItem('sessionId', sessionId);

      console.log('New sessionId generated:', sessionId);
      console.log('UserName set:', name);

      navigate("/questionnaire");
    }
  };

  return (
    <div className="name-input-screen">
      <form onSubmit={handleSubmit}>
        <h2>Hi, my name is Avani</h2>
        <p>I'll be your guide on this journey we're about to take together. Every great story starts with a name. What should I call you?</p>
        <br/>
        <input
          id="nameInput"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          className="name-input"
        />
        <br/>
        <button type="submit" className="continue-button p-text">
          Start your journey
        </button>
      </form>
    </div>
  );
}

export default NameInputScreen;
