import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/IntroScreen.css";

function IntroScreen() {
  const [fadeInTitle, setFadeInTitle] = useState(false);
  const [fadeInParagraphs, setFadeInParagraphs] = useState(false);
  const [arrowAnimation, setArrowAnimation] = useState(false);
  const [slideOut, setSlideOut] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("IntroScreen mounted");
    const titleTimeout = setTimeout(() => {
      setFadeInTitle(true);
    }, 100);

    const paragraphsTimeout = setTimeout(() => {
      setFadeInParagraphs(true);
    }, 800);

    const arrowTimeout = setTimeout(() => {
      setArrowAnimation(true);
    }, 2000);

    return () => {
      console.log("Cleaning up timeouts");
      clearTimeout(titleTimeout);
      clearTimeout(paragraphsTimeout);
      clearTimeout(arrowTimeout);
    };
  }, []);

  const handleContinueClick = () => {
    console.log("Continue button clicked");
    setArrowAnimation(false);
    setSlideOut(true);
    setTimeout(() => {
      console.log("Navigating to /nameInput");
      navigate("/nameInput");
    }, 600);
  };

  return (
    <div className="intro-screen">
      <h1 className={`intro-title ${fadeInTitle ? "fade-in" : ""} ${slideOut ? "slide-out-title" : ""}`}>
        Welcome to the Life Meaning App
      </h1>
      <p className={`intro-text ${fadeInParagraphs ? "fade-in" : ""} ${slideOut ? "slide-out-paragraph" : ""}`}>
        Discover insights into your life's meaning by answering a few thought-provoking questions.
      </p>
      <p className={`intro-text ${fadeInParagraphs ? "fade-in" : ""} ${slideOut ? "slide-out-paragraph" : ""}`}>
        Let's begin this journey together to understand what truly matters to you.
      </p>
      <button
        className={`intro-arrow-button ${arrowAnimation ? "bounce" : ""} ${slideOut ? "fade-out" : ""}`}
        onClick={handleContinueClick}
      >
        ➔
      </button>
    </div>
  );
}

export default IntroScreen;
