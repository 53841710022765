// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/LoadingScreen.css */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100svh; /* Use Small Viewport Height for better centering on mobile */
  min-height: 100vh;  /* Fallback for standard Viewport Height */
  background-color: #f5f5dc; /* Beige background */
  font-family: "Courier New", Courier, monospace !important; /* Typewriter font */
}

.quote-container {
  text-align: center;
  max-width: 80%;
  overflow: hidden; /* Ensure the text stays within the container */
}

.typewriter-text {
  font-size: 2.4rem; /* 24px converted to rem */
  color: #000000; /* Black font */
  white-space: nowrap;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace !important;

  /* Hide the default text cursor */
  -webkit-user-select: none;
          user-select: none; /* Prevent text selection */
  cursor: default; /* Set cursor to default to hide text cursor */

  /* Initial animation state */
  transform: translateY(0);
  opacity: 0.6;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out; /* Animation transition */
}

.typewriter-text.slide-down {
  transform: translateY(100%); /* Slide text down */
  opacity: 0; /* Fade out */
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoadingScreen.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB,EAAE,6DAA6D;EACjF,iBAAiB,GAAG,0CAA0C;EAC9D,yBAAyB,EAAE,qBAAqB;EAChD,yDAAyD,EAAE,oBAAoB;AACjF;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB,EAAE,+CAA+C;AACnE;;AAEA;EACE,iBAAiB,EAAE,0BAA0B;EAC7C,cAAc,EAAE,eAAe;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,yDAAyD;;EAEzD,iCAAiC;EACjC,yBAAiB;UAAjB,iBAAiB,EAAE,2BAA2B;EAC9C,eAAe,EAAE,8CAA8C;;EAE/D,4BAA4B;EAC5B,wBAAwB;EACxB,YAAY;EACZ,0DAA0D,EAAE,yBAAyB;AACvF;;AAEA;EACE,2BAA2B,EAAE,oBAAoB;EACjD,UAAU,EAAE,aAAa;AAC3B","sourcesContent":["/* src/styles/LoadingScreen.css */\n\n.loading-screen {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  min-height: 100svh; /* Use Small Viewport Height for better centering on mobile */\n  min-height: 100vh;  /* Fallback for standard Viewport Height */\n  background-color: #f5f5dc; /* Beige background */\n  font-family: \"Courier New\", Courier, monospace !important; /* Typewriter font */\n}\n\n.quote-container {\n  text-align: center;\n  max-width: 80%;\n  overflow: hidden; /* Ensure the text stays within the container */\n}\n\n.typewriter-text {\n  font-size: 2.4rem; /* 24px converted to rem */\n  color: #000000; /* Black font */\n  white-space: nowrap;\n  overflow: hidden;\n  font-family: \"Courier New\", Courier, monospace !important;\n\n  /* Hide the default text cursor */\n  user-select: none; /* Prevent text selection */\n  cursor: default; /* Set cursor to default to hide text cursor */\n\n  /* Initial animation state */\n  transform: translateY(0);\n  opacity: 0.6;\n  transition: transform 0.6s ease-out, opacity 0.6s ease-out; /* Animation transition */\n}\n\n.typewriter-text.slide-down {\n  transform: translateY(100%); /* Slide text down */\n  opacity: 0; /* Fade out */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
