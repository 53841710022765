import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import loaderGif from '../images/loader3.gif';
import '../styles/PremiumLoading.css'; // Create a new CSS file for this component

function PremiumLoading() {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [ellipsis, setEllipsis] = useState('');
  const navigate = useNavigate();
  const sessionId = sessionStorage.getItem('sessionId');
  const userName = sessionStorage.getItem('userName');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const hasPerformedAnalysis = useRef(false);
  
  const loadingMessages = [
    'We are preparing your premium experience',
    'Analyzing your unique preferences',
    'Generating personalized insights',
    'Just a moment, almost there',
  ];

  useEffect(() => {
    const messageTimer = setInterval(() => {
      setLoadingMessageIndex((prevIndex) => {
        if (prevIndex === loadingMessages.length - 1) {
          clearInterval(messageTimer);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 6000);

    return () => clearInterval(messageTimer);
  }, []);

  useEffect(() => {
    const ellipsisTimer = setInterval(() => {
      setEllipsis((prevEllipsis) => {
        switch (prevEllipsis) {
          case '':
            return '.';
          case '.':
            return '..';
          case '..':
            return '...';
          case '...':
            return '';
          default:
            return '';
        }
      });
    }, 1000);

    return () => clearInterval(ellipsisTimer);
  }, []);

  useEffect(() => {
    if (hasPerformedAnalysis.current) {
      return;
    }
  
    hasPerformedAnalysis.current = true;
  
    const performPremiumAnalysis = async () => {
      console.log('[PremiumLoading] Calling performPremiumAnalysis.');
      try {
        const response = await axios.post(`${apiBaseUrl}/api/premium/premium-analysis`, {
          sessionId,
          userName,
        });
  
        const premiumResult = response.data.premiumInstructions;
        const correlationMatrix = response.data.correlationMatrix;
  
        // Save results in session storage
        sessionStorage.setItem('premiumResult', premiumResult);
        sessionStorage.setItem('correlationMatrix', JSON.stringify(correlationMatrix));
  
        // Navigate to the result screen
        navigate('/premium-content');
      } catch (error) {
        console.error('Error performing premium analysis:', error);
      }
    };
  
    performPremiumAnalysis();
  }, [apiBaseUrl, sessionId, userName, navigate]);

  return (
    <div className="premium-loading-screen">
      <img src={loaderGif} alt="Loading..." className="loader-gif" />
      <p>
        {loadingMessages[loadingMessageIndex]}
        <span className="ellipsis">{ellipsis}</span>
      </p>
    </div>
  );
}

export default PremiumLoading;
