import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import IntroScreen from "./components/IntroScreen";
import NameInputScreen from "./components/NameInputScreen";
import Questionnaire from "./components/Questionnaire";
import ResultScreen from "./components/ResultScreen";
import ActivateEnlightenedMode from "./components/ActivateEnlightenedMode";
import PremiumLoading from './components/PremiumLoading';
import "./styles/App.css";
import PremiumResultScreen from "./components/PremiumResultScreen";

function App() {
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [resultsReady, setResultsReady] = useState(
    () => sessionStorage.getItem("resultsReady") === "true"
  );

  useEffect(() => {
    // Set a timer to transition from loading to intro
    const timer = setTimeout(() => {
      setLoadingFinished(true);
    }, 3700);

    return () => clearTimeout(timer);
  }, []);

  const handleResultsReady = () => {
    setResultsReady(true);
    sessionStorage.setItem("resultsReady", "true");
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={!loadingFinished ? <LoadingScreen /> : <Navigate to="/intro" />} />
        <Route path="/intro" element={<IntroScreen />} />
        <Route path="/nameInput" element={<NameInputScreen />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/result" element={<ResultScreen /> } />
        <Route path="/activate-enlightened-mode" element={ <ActivateEnlightenedMode /> } />
        <Route path="/premium-loading" element={<PremiumLoading />} />
        <Route path="/premium-content" element={<PremiumResultScreen />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
