import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import '../styles/PremiumResultScreen.css';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

function PremiumResultScreen() {
  const [premiumResult, setPremiumResult] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [correlationMatrix, setCorrelationMatrix] = useState(null);
  const [selectedCorrelation, setSelectedCorrelation] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const userName = sessionStorage.getItem('userName');

  useEffect(() => {
    const storedPremiumResult = sessionStorage.getItem('premiumResult');
    const storedScores = sessionStorage.getItem('percentageScores');
    const storedCorrelationMatrix = sessionStorage.getItem('correlationMatrix');

    if (storedPremiumResult) {
      const parsedResult = JSON.parse(storedPremiumResult);
      setPremiumResult(parsedResult);
    }

    if (storedScores) {
      const userScores = JSON.parse(storedScores);

      const averageScores = {
        "Purpose and Meaning": 45,
        "Life Satisfaction": 43,
        "Personal Growth": 42,
        "Social Connection": 48,
        "Emotional Well-Being": 50,
        "Resilience": 48,
        "Autonomy": 52,
        "Mindfulness": 46
      };

      const labels = Object.keys(averageScores);
      const userScoreValues = labels.map(label => userScores[label] !== undefined ? userScores[label] : 0);
      const averageScoreValues = Object.values(averageScores);

      const allScores = [...userScoreValues, ...averageScoreValues];
      const minScore = Math.max(1, Math.min(...allScores) - 10);
      const maxScore = Math.min(100, Math.max(...allScores) + 10);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Average Person',
            data: averageScoreValues,
            backgroundColor: 'rgba(34, 202, 236, 0.2)',
            borderColor: 'rgba(34, 202, 236, 1)',
            borderWidth: 1,
            fill: true,
          },
          {
            label: `${userName}'s Scores`,
            data: userScoreValues,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            fill: true,
          },
        ],
        options: {
          scales: {
            r: {
              angleLines: { display: true },
              suggestedMin: minScore,
              suggestedMax: maxScore,
              grid: {
                color: getComputedStyle(document.documentElement).getPropertyValue('--background-color'),
              },
              ticks: {
                backdropColor: 'transparent',
              },
            },
          },
          plugins: {
            legend: { display: true },
          },
        },
      });
    }

    if (storedCorrelationMatrix) {
      setCorrelationMatrix(JSON.parse(storedCorrelationMatrix));
    }
  }, [userName]);

  const handleGridItemClick = (row, col, value) => {
    setSelectedCorrelation({ row, col, value });
  };

  const handleNextSection = () => {
    if (premiumResult && currentSectionIndex < premiumResult.letter.sections.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
  
      const topElement = document.getElementById('result-premium-content');
      if (topElement) {
        topElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const renderChart = () => {
    return (
      <Radar data={chartData} options={chartData.options} />
    );
  };

  const renderTable = () => {
    return (
      <div className="table-container">
        <table className="heatmap-table">
          <tbody>
            {Object.keys(correlationMatrix).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(correlationMatrix[row]).map((col, colIndex) => {
                  const value = correlationMatrix[row][col].toFixed(2);
                  const bgColor = `rgba(0, 0, 255, ${Math.abs(value)})`;
                  return (
                    <td
                      key={colIndex}
                      style={{ backgroundColor: bgColor }}
                      onClick={() => handleGridItemClick(row, col, value)}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const replacePlaceholders = (content) => {
    let updatedContent = content;

    if (chartData) {
      updatedContent = updatedContent.replace('[INSERT_CHART_HERE]', `<div id="chart-placeholder"></div>`);
    }

    if (correlationMatrix) {
      updatedContent = updatedContent.replace('[INSERT_TABLE_HERE]', `<div id="table-placeholder"></div>`);
    }

    return updatedContent;
  };

  useEffect(() => {
    if (premiumResult) {
      const currentSection = premiumResult.letter.sections[currentSectionIndex];
      const contentWithPlaceholders = replacePlaceholders(currentSection.finalContent.replace('${userName}', userName || ''));

      document.getElementById('result-premium-content').innerHTML = contentWithPlaceholders;

      if (chartData) {
        const chartPlaceholder = document.getElementById('chart-placeholder');
        if (chartPlaceholder) {
          const root = createRoot(chartPlaceholder);  // Use createRoot for React 18
          root.render(renderChart());
        }
      }

      if (correlationMatrix) {
        const tablePlaceholder = document.getElementById('table-placeholder');
        if (tablePlaceholder) {
          const root = createRoot(tablePlaceholder);  // Use createRoot for React 18
          root.render(renderTable());
        }
      }
    }
  }, [premiumResult, chartData, correlationMatrix, currentSectionIndex, userName]);

  return (
    <div className="result-screen">
      {premiumResult ? (
        <>
          <h2 className="main-heading">Your Personalized Premium Results<br/> ------</h2>
          <div id="result-premium-content"></div>
          {currentSectionIndex < premiumResult.letter.sections.length - 1 && (
            <button onClick={handleNextSection} className="premium-next-button">Next</button>
          )}
        </>
      ) : (
        <p>Premium results not found. Please try again.</p>
      )}

      {selectedCorrelation && (
        <div className="modal-tooltip">
          <h4>Correlation Details</h4>
          <p><strong>{selectedCorrelation.row}</strong> ↔ <strong>{selectedCorrelation.col}</strong></p>
          <p>Value: {selectedCorrelation.value}</p>
          <button onClick={() => setSelectedCorrelation(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default PremiumResultScreen;
