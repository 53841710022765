import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loaderGif from '../images/loader3.gif'; 
import { v4 as uuidv4 } from 'uuid';
import '../styles/Questionnaire.css';

function Questionnaire() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [dragPercentage, setDragPercentage] = useState(50);
  const [arrowAnimation, setArrowAnimation] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [sparkles, setSparkles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [ellipsis, setEllipsis] = useState('');
  const navigate = useNavigate();
  
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  const SNAP_THRESHOLD = 3; 
  const EDGE_SENSITIVITY = 2;

  useEffect(() => {
    const sessionId = sessionStorage.getItem('sessionId');
    const userName = sessionStorage.getItem('userName');

    console.log('[Questionnaire] [On Navigation] Session ID:', sessionId);
    console.log('[Questionnaire] [On Navigation] UserName in sessionStorage:', userName);

    if (!userName || !sessionId) {
      console.log('[Questionnaire] [On Navigation] No userName or sessionId found in sessionStorage. Redirecting to home.');
      navigate('/');
    }
  }, [navigate]);

  const loadingMessages = [
    'We are collecting your results',
    'Results are being analysed',
    'Personalized letter is being written',
    'Results will arrive shortly',
  ];

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/questions`);
        setQuestions(response.data);
        console.log('[Questionnaire] Questions fetched successfully:', response.data);
      } catch (error) {
        console.error('[Questionnaire] Error fetching questions:', error);
      }
    };
  
    if (questions.length === 0) {
      fetchQuestions();
    }
  }, [apiBaseUrl, questions.length]); 

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      const timer = setTimeout(() => {
        setArrowAnimation(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDragPercentage(50);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (isLoading) {
      const messageTimer = setInterval(() => {
        setLoadingMessageIndex((prevIndex) => {
          if (prevIndex === loadingMessages.length - 1) {
            clearInterval(messageTimer);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 6000);

      return () => clearInterval(messageTimer);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      const ellipsisTimer = setInterval(() => {
        setEllipsis((prevEllipsis) => {
          switch (prevEllipsis) {
            case '':
              return '.';
            case '.':
              return '..';
            case '..':
              return '...';
            case '...':
              return '';
            default:
              return '';
          }
        });
      }, 1000);

      return () => clearInterval(ellipsisTimer);
    }
  }, [isLoading]);

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const { clientX } = e;
    let percentage = (clientX / window.innerWidth) * 100;

    if (percentage < EDGE_SENSITIVITY) {
      percentage = percentage / 2;
    } else if (percentage > 100 - EDGE_SENSITIVITY) {
      percentage = 100 - (100 - percentage) / 2;
    }

    if (percentage < SNAP_THRESHOLD) {
      percentage = 1;
    } else if (percentage > 100 - SNAP_THRESHOLD) {
      percentage = 100;
    }

    setDragPercentage(Math.min(Math.max(percentage, 1), 100)); 
    createSparkle(e.clientX, e.clientY);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    let percentage = (touch.clientX / window.innerWidth) * 100;

    if (percentage < EDGE_SENSITIVITY) {
      percentage = percentage / 2;
    } else if (percentage > 100 - EDGE_SENSITIVITY) {
      percentage = 100 - (100 - percentage) / 2;
    }

    if (percentage < SNAP_THRESHOLD) {
      percentage = 1;
    } else if (percentage > 100 - SNAP_THRESHOLD) {
      percentage = 100;
    }

    setDragPercentage(Math.min(Math.max(percentage, 1), 100)); 
    createSparkle(touch.clientX, touch.clientY);
  };

  const createSparkle = (x, y) => {
    const sparkle = {
      id: uuidv4(),
      x,
      y,
    };
    setSparkles((prevSparkles) => [...prevSparkles, sparkle]);

    setTimeout(() => {
      setSparkles((prevSparkles) => prevSparkles.filter((s) => s.id !== sparkle.id));
    }, 1000);
  };

  const handleNextQuestion = async () => {
    setArrowAnimation(false);
    setDragPercentage(50);

    const sessionId = sessionStorage.getItem('sessionId');
    const userName = sessionStorage.getItem('userName');

    try {
      await axios.post(`${apiBaseUrl}/api/users/response`, {
        sessionId,
        userName,
        questionId: questions[currentQuestionIndex]?._id,
        answer: dragPercentage,
      });

      console.log('Response saved successfully.');
    } catch (error) {
      console.error('Error saving response:', error);
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      setTimeout(() => {
        setDragPercentage(50);
        document.querySelector('.questionnaire').classList.remove('resetting');
      }, 100);
    } else {
      setIsLoading(true);

      try {
        const scoreResponse = await axios.post(`${apiBaseUrl}/api/users/score`, {
          sessionId,
          userName,
        });

        const cleanedResult = scoreResponse.data.instructions.replace(/```html|```/g, '');
        sessionStorage.setItem('resultsReady', true);
        sessionStorage.setItem('result', cleanedResult); 
        setIsLoading(false);

        sessionStorage.setItem('percentageScores', JSON.stringify(scoreResponse.data.userScore.percentageScores));
        sessionStorage.setItem('achievedScores', JSON.stringify(scoreResponse.data.userScore.scores));

        console.log('Scores and result stored in sessionStorage.');
        navigate('/result'); 
      } catch (error) {
        console.error('Error calculating scores:', error);
        setIsLoading(false);
      }
    }
  };

  const agreementColor = `linear-gradient(to right, #0597F2 0%, #0597F2 ${dragPercentage - 90}%, #ACCAF2 ${dragPercentage - 0}%, #f5f5dc ${dragPercentage + 0}%, #f5f5dc 100%)`;

  return (
    <div>
      {isLoading ? (
        <div className="loading-screen">
          <img src={loaderGif} alt="Loading..." className="loader-gif" />
          <p>
            {loadingMessages[loadingMessageIndex]}
            <span className="ellipsis">{ellipsis}</span>
          </p>
        </div>
      ) : (
        <div
          className="questionnaire"
          style={{ background: agreementColor }}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchMove={handleTouchMove}
        >
          <div className="question-counter">
            {currentQuestionIndex + 1} / {questions.length}
          </div>
          <div className="question-container">
            {questions.length > 0 ? (
              <h2>{questions[currentQuestionIndex]?.text}</h2>
            ) : (
              <h2>Loading questions...</h2>
            )}
            <div className="slider-value">{Math.round(dragPercentage)}%</div>
          </div>
          <div className="slider-shadow" style={{ left: `${dragPercentage}%` }}></div>
          {sparkles.map((sparkle) => (
            <div
              key={sparkle.id}
              className="sparkle"
              style={{ left: sparkle.x, top: sparkle.y }}
            ></div>
          ))}
          <button
            className={`arrow-button ${
              arrowAnimation && currentQuestionIndex === 0 ? 'bounce' : ''
            }`}
            onClick={handleNextQuestion}
          >
            ➔
          </button>
        </div>
      )}
    </div>
  );
}

export default Questionnaire;
