import React, { useState, useEffect } from 'react';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ActivateEnlightenedMode from './ActivateEnlightenedMode';
import '../styles/ResultScreen.css';

// Register the necessary components
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function ResultScreen() {
  const [showActivateMode, setShowActivateMode] = useState(false);
  const [result, setResult] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const storedResult = sessionStorage.getItem('result');
    const storedPercentageScores = sessionStorage.getItem('percentageScores');

    console.log('Stored result in sessionStorage:', storedResult);
    console.log('Stored percentageScores in sessionStorage:', storedPercentageScores);

    if (storedResult) {
      setResult(storedResult);
      console.log('Result set successfully.');
    } else {
      console.log('No result found in sessionStorage.');
    }

    if (storedPercentageScores) {
      try {
        const scores = JSON.parse(storedPercentageScores);
        console.log('Parsed percentageScores:', scores);

        const categories = Object.keys(scores);

        // Generate color intensity based on score
        const backgroundColors = categories.map((factor) => {
          const score = scores[factor];
          const intensity = Math.floor((score / 100) * 255); // Scale to 0-255
          return `rgb(${255 - intensity}, ${255 - intensity}, ${255})`; // Shades of blue
        });

        setChartData({
          labels: categories, // Labels on Y-axis
          datasets: [
            {
              label: 'Impact Factor Scores',
              data: Object.values(scores),
              backgroundColor: backgroundColors,
              borderColor: 'rgba(34, 202, 236, 1)',
              borderWidth: 1,
            },
          ],
        });

        console.log('Chart data set successfully.');
      } catch (error) {
        console.error('Error parsing percentageScores:', error);
      }
    } else {
      console.log('No percentageScores found in sessionStorage.');
    }
  }, []);

  if (showActivateMode) {
    return <ActivateEnlightenedMode />;
  }

  return (
    <div className="result-screen">
      {result ? (
        <>
          <h2>Your Personalized Letter</h2>
          <div dangerouslySetInnerHTML={{ __html: result.replace('[Your Name]', '') }} />
        </>
      ) : (
        <p>Results not found. Please try again.</p>
      )}
      {chartData && (
        <div className="chart-container">
          <h3>Your Impact Factor Scores</h3>
          <Bar
            data={chartData}
            options={{
              indexAxis: 'y', // Flip the axes to make it horizontal
              maintainAspectRatio: false,
              scales: {
                x: {
                  beginAtZero: true,
                  max: 100, // Assuming the percentage score ranges between 0 and 100
                },
              },
              plugins: {
                legend: { display: false }, // Hide the legend for simplicity
              },
            }}
          />
        </div>
      )}
      <a
        href="#"
        className="activate-button"
        onClick={() => setShowActivateMode(true)}
      >
        <span>Activate Enlightened Mode</span>
      </a>
    </div>
  );
}

export default ResultScreen;
