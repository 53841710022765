// src/components/LoadingScreen.js

import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import "../styles/LoadingScreen.css";

function LoadingScreen() {
  const [animateSlideDown, setAnimateSlideDown] = useState(false);
  const quote = "You only live once.";

  useEffect(() => {
    // Set the slide-down animation to trigger after 3 seconds
    const timer = setTimeout(() => {
      setAnimateSlideDown(true);
    }, 3000); // 3000 milliseconds (3 seconds)

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div className="loading-screen">
      <div className="quote-container">
        <div className={`typewriter-text ${animateSlideDown ? "slide-down" : ""}`}>
          <Typewriter
            options={{
              strings: [quote],
              autoStart: true,
              loop: false,
              delay: 50,
              cursor: "|", // Customize the typewriter cursor here
              deleteSpeed: Infinity, // Disable reverse typing by setting delete speed to a very high value
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
